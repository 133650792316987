<template>
    <div class="success">
        <img src="https://khome2.tuzuu.com/vita/ahome_1.png">
        <img src="https://khome2.tuzuu.com/vita/logo.png" class="logo">

        <div class="infor">
            <div class="title">收货信息</div>
            <div class="confirm-text">
                <div>
                    <span>{{ $$(data.consignee_info, 'name') }}</span>
                    <span style="margin-right: 22px">
                        {{ data.consignee_info.phone.slice(0, 3) }} {{ data.consignee_info.phone.slice(3, 7) }} {{ data.consignee_info.phone.slice(7, 11) }}
                    </span>
                </div>
                <div>
                    <span>{{ $$(data.consignee_info, 'address') }}</span>
                </div>
            </div>
            <div class="title" style="margin-top: 15px">支付人信息</div>
            <div class="confirm-text">
                <div>
                    <span>{{ $$(data.pay_info, 'name') }}</span>
                </div>
                <div>
                    <span>{{ $$(data.pay_info, 'id_no') }}</span>
                </div>
            </div>
        </div>

        <div class="content">
            <div class="cell flex">
                <img src="https://khome2.tuzuu.com/vita/icon_6.png">
                支付成功
            </div>
            <div class="cell2">
                您将获得以下健康权益
            </div>
            <div class="sub-title">
                定制检测服务
            </div>
            <div class="info">
                通过遗传基因、基因分析、营养成分，寻找最适合你的健康方案
            </div>

            <div class="sub-title">
                营养顾问1对1服务
            </div>
            <div class="info">
                有什么问题，来找我们，让我们经验丰富的营养师为您解忧
            </div>

            <div class="sub-title">
                360天定制营养包<span>/ 保持新鲜 分阶段直运</span>
            </div>
            <div class="info">
                海外直运，每天一小包，让健康简简单单
            </div>

        </div>
    </div>
</template>

<script>
    export default {
        name: "",
        data() {
            return {
                data: {
                    consignee_info: {
                        phone: ''
                    }
                }
            }
        },
        created() {
            let sn = this.$route.params.sn
            let toast = this.$toast.loading({
                message: '加载中',
                forbidClick: true,
                overlay:true
            });
            this.$request.get(
                "order_detail", {
                    order_sn: sn
                }
            ).then(res => {
                if (res.code === 1) {
                    console.log(res.data)
                    this.data = res.data
                    toast.clear()
                } else {
                    this.$toast(res.message)
                }
            })
        }
    }
</script>

<style scoped lang="less">
    .success {
        width: 100%;
        min-height: 100vh;
        background-color: #F2F2F2;
        font-size: 16px;
        position: relative;
        padding-bottom: 30px;
    }

    .infor {
        width: calc(100% - 20px);
        margin: 0 auto;
        margin-top: -100px;
        margin-bottom: 15px;
        padding: 15px 22px;
        border-radius: 10px;
        background-color: white;
        position: relative;
        box-sizing: border-box;

        .title {
            font-size: 14px;
            font-weight: 700;
            margin-bottom: 9px;
        }

        .confirm-text {
            font-size: 14px;
            color: #666666;
            line-height: 25px;
        }
    }

    .content {
        width: calc(100% - 20px);
        margin: 0 auto;
        background-color: white;
        position: relative;
        border-radius: 10px;
        padding: 20px 22px;
        box-sizing: border-box;
        padding-bottom: 35px;

        .cell{
            font-size: 14px;
            margin-bottom: 15px;
            font-weight:700;
            img{
                width: 22px;
                margin-right: 7px;
            }
        }
        .cell2{
            font-size: 14px;
            color: #292C2F;
            margin-bottom: 25px;
        }
        .sub-title{
            font-size: 16px;
            color: #292C2F;
            font-weight: 900;
            border-bottom: 1px solid #292C2F;
            padding-bottom: 5px;
            margin-bottom: 4px;
            margin-top: 30px;
            span{
                font-size: 12px;
                color: #666666;
                margin-left: 4px;
                font-weight: 400;
            }
        }
        .info{
            font-size: 14px;
            color: #666666;
            line-height: 20px;
            letter-spacing: 0.5px;
            font-family: HYQiHei-EES, HYQiHei;
        }
    }
</style>